import React, { useState, useEffect } from 'react';
import Initial from './Initial';
import Loading from './Loading';
import HowToPlay from './HowToPlay';
import HowToWin from './HowToWin';
import GamePlay from './GamePlay';
import Wait00 from './Wait/Wait00';
import Wait01 from './Wait/Wait01';
import Wait02 from './Wait/Wait02';
import Wait03 from './Wait/Wait03';
import Wait04 from './Wait/Wait04';
import Wait05 from './Wait/Wait05';
import Wait06 from './Wait/Wait06';
import Wait07 from './Wait/Wait07';
import Wait08 from './Wait/Wait08';
import Wait99 from './Wait/Wait99';
import Neo00 from './Neo/Neo00';
import Dashboard from './Dashboard';
import { fetchGameData, fetchLandmarkImage } from './api';
import initPrompt from './prompt'
import './App.css';
import { auth, firestore } from './firebase-config';
import { collection, query, where, getDocs, limit, orderBy } from 'firebase/firestore';


function App() {
  const [usedLetters, setUsedLetters] = useState([]);
  const [nextGameData, setNextGameData] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [guesses, setGuesses] = useState({});
  const [landmarkImages, setLandmarkImages] = useState([]);
  const [guessHistory, setGuessHistory] = useState([]);
  const [guessesRemaining, setGuessesRemaining] = useState(3);
  const [gameStatus, setGameStatus] = useState('initial');

  // If logged in, avoid 10 most popular letters
  useEffect(() => {
    const fetchLettersForLoggedInUser = async () => {
      if (auth.currentUser) {
        const scoresRef = collection(firestore, "gameResults");
        const q = query(scoresRef, where("userId", "==", auth.currentUser.uid), orderBy("timestamp", "desc"), limit(250));
        const docs = await getDocs(q);
        const letterCounts = {};
        docs.forEach(doc => {
          const letter = doc.data().letter;
          if (letterCounts[letter]) {
            letterCounts[letter]++;
          } else {
            letterCounts[letter] = 1;
          }
        });
        // Find top 10 most common letters
        const topLetters = Object.entries(letterCounts).sort((a, b) => b[1] - a[1]).slice(0, 10).map(entry => entry[0]);
        setUsedLetters(topLetters);
      } else {
        setUsedLetters([]);
      }
    };

    fetchLettersForLoggedInUser();
  }, []);

  // Prepare for the next game, load response from OpenAI API
  const fetchNextGameData = async () => {
    const letterToExclude = usedLetters.join(', ');
    const modifiedPrompt = `${initPrompt} There's an additional restriction to Step 1. Exclude the following letters: [${letterToExclude}]`;
    const nextData = await fetchGameData(modifiedPrompt);
    setNextGameData(nextData);
    if (nextData && nextData.letter) {
      setUsedLetters(prev => [...prev, nextData.letter]);
    }
  };

  // Alway check if the data for the next game is already loaded
  useEffect(() => {
    fetchNextGameData();
  }, []);

  const startGame = async () => {
    if (nextGameData) {
      setGuesses({});
      setGuessHistory([]);
      setGuessesRemaining(3);
      setGameStatus('loading');
      
      const images = await Promise.all(nextGameData.landmarks.map((landmark, index) =>
        fetchLandmarkImage(landmark, nextGameData.countries[index])
      ));
      setLandmarkImages(images);
      setGameData(nextGameData);
      
      setGameStatus('playing');
      fetchNextGameData();
    } else {
      alert("AI is working on your puzzle. Wait and try again.");
    }
  };

  return (
    <div className="App">
      <div className="App-header container">
        {gameStatus === 'loading' ? (
          <Loading />
        ) : gameStatus === 'initial' ? (
          <Initial setGameStatus={setGameStatus} startGame={startGame} />
        ) : gameStatus === 'howToPlay' ? (
          <HowToPlay setGameStatus={setGameStatus} />
        ) : gameStatus === 'howToWin' ? (
          <HowToWin setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait00' ? (
          <Wait00 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait01' ? (
          <Wait01 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait02' ? (
          <Wait02 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait03' ? (
          <Wait03 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait04' ? (
          <Wait04 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait05' ? (
          <Wait05 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait06' ? (
          <Wait06 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait07' ? (
          <Wait07 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait08' ? (
          <Wait08 setGameStatus={setGameStatus} />
        ) : gameStatus === 'wait99' ? (
          <Wait99 setGameStatus={setGameStatus} startGame={startGame}/>
        ) : gameStatus === 'neo00' ? (
          <Neo00 setGameStatus={setGameStatus} />
        ) : gameStatus === 'dashboard' ? (
          <Dashboard setGameStatus={setGameStatus} />
        ) : (
          <GamePlay 
            startGame={startGame} 
            gameData={gameData} 
            guesses={guesses} 
            setGuesses={setGuesses}
            landmarkImages={landmarkImages} 
            guessHistory={guessHistory} 
            guessesRemaining={guessesRemaining} 
            setGuessHistory={setGuessHistory}
            setGuessesRemaining={setGuessesRemaining}
            gameStatus={gameStatus}
            setGameStatus={setGameStatus}
          />
        )}
      </div>
    </div>
  );
}

export default App;
