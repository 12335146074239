import React, { useEffect, useState } from 'react';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';


function Wait04({ setGameStatus }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return unsubscribe; // Cleanup subscription on unmount
    }, []);

    return (
        <div>
            <h2 className='text-2xl mb-2'>You Will Not Play the Game Now</h2>
            <div className='text-justify sm:mx-2'>
                <p>I understand. You will not play "Landmarkle" now because you are busy.</p>
                <br/>
                <p>You are busy playing <i>this</i> game, right here. Good. Let's keep playing.</p>
                <br/>
                <p>Do you often find yourself playing a completely different game from most people around you?</p>
                <br/>
            </div>
            
            <p className='text-xl mb-2'>
                Is this normal for you
                {user && (
                    <span>, {user.displayName.split(" ")[0]}</span>
                )}
                ?
                </p>
            <div className='grid grid-cols-2 flex items-center gap-4'>
                <button 
                className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
                onClick={() => setGameStatus('initial')}>
                    No
                </button>
                <button 
                className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
                onClick={() => setGameStatus('wait05')}>
                    Yes
                </button>
            </div>
        </div>
    );
}

export default Wait04;