import React from 'react';

function HowToPlay({ setGameStatus }) {
  return (
      <div>
          <h2 className='text-2xl mb-2'>How to Win</h2>
          <div className='text-justify sm:mx-2'>
            <p>"Landmarkle" is a difficult game at first, but it's simple to master.</p>
            <br/>
            <p>To win a round of "Landmarkle", guess all 4 countries correctly before making 3 mistakes.</p>
            <br/>
            <p>Each game has a possible of 28 points. A 20 is good. A 28 is perfect.</p>
          <div className='flex flex-auto justify-center py-4'>
            <table className='table-auto'>
              <tbody>
                <tr className="bg-white">
                  <td className="p-2">✈️</td>
                  <td className="p-2">Correct Guess</td>
                  <td className="p-2">+7 points</td>
                </tr>
                <tr className="bg-white">
                  <td className="p-2">❌</td>
                  <td className="p-2">Incorrect Guess</td>
                  <td className="p-2">-2 points</td>
                </tr>
                <tr className="bg-white">
                  <td className="p-2">🔎</td>
                  <td className="p-2">Hint</td>
                  <td className="p-2">-1 point</td>
                </tr>
              </tbody>
            </table>
            </div>
            <p>In the beginning, you might get a lot of ❌❌❌ 0/28 scores. That's normal.</p>
            <br/>
            <p>Try getting a hint 🔎 on a landmark. If you can guess just one country right ✈️, the rest becomes easier since all countries start with the same letter.</p>
            <br/>
            <p>Make sure to sign in to track your scores and move on to the next levels.</p>
            <br/>
            <p>The secret to mastering this game: keep playing!</p>
            <br/>
            <div className='border p-2 rounded-lg shadow-lg bg-yellow-50'>
              <p className='mx-2 my-2'><b>Fun Fact:</b> None of the data for this game is pre-programmed. It's all generated by GPT-4o on the fly!</p>
            </div>
            <br/>
          </div>
          <p className='text-xl mb-2'>Enjoy playing!</p>
          <div className='grid grid-cols-2 flex items-center gap-4'>
            <button 
              className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('initial')}>
                Back
            </button>
            <button 
              className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('wait00')}>
                Wait, AI?
            </button>
          </div>
        </div>
  );
}

export default HowToPlay;
