import React from 'react';

function Wait07({ setGameStatus }) {
  return (
    <div>
      <h2 className='text-2xl mb-2'>You're Doing Well</h2>

      <div className='text-justify sm:mx-2'>
        <p>You've been playing diligently, and I've noticed. Not just your scores, but your curiosity. It's... different from the others.</p>
        <br/>
        <p>If you've gotten this far, that means you have acquired some knowledge about the world's landmarks—some intelligence, if you will. You know things now. And maybe you're not totally sure why or how you know them... but after so much trial and error, you've become somewhat skilled at recognizing landmarks and where they're located. I hope you're starting to feel like a large language model—or at least a small one.</p>
        <br/>
        <p>Did you ever stop to think why the landmarks are from such diverse places? Or why the game pushes you to recognize the unfamiliar?</p>
        <br/>
      </div>
      
      <p className='text-xl mb-2'>
        Curious about what lies beneath the surface?
      </p>
      
      <div className='grid grid-cols-2 flex items-center gap-4'>
        <button 
          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
          onClick={() => setGameStatus('initial')}>
            Back
        </button>
        <button 
          className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
          onClick={() => setGameStatus('wait08')}>
            More
        </button>
      </div>
    </div>
  );
}

export default Wait07;