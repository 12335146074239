import React from 'react';

function HowToPlay({ setGameStatus }) {
  return (
      <div>
          <h2 className='text-2xl mb-2'>Guess the countries based on the landmarks.</h2>
          <p>All countries start with the same letter.</p>
          <p>Guess all 4 countries with fewer than 3 incorrect guesses.</p>
          <p>Share your score on social media. Log in with Google to track your scores.</p>
          <br/>
          <p className='text-xl mb-2'>Enjoy playing!</p>
          <div className='grid grid-cols-2 flex items-center gap-4'>
            <button 
              className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('initial')}>
                Back
            </button>
            <button 
              className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('howToWin')}>
                How to Win
            </button>
          </div>
        </div>
  );
}

export default HowToPlay;
