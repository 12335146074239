import React, { useState, useRef, useEffect } from 'react';
import GameTile from './GameTile';
import allCountries from './allCountries';
import { firestore } from './firebase-config';
import { collection, addDoc, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth } from './firebase-config';


function GamePlay({ startGame, gameData, guesses, setGuesses, landmarkImages, guessHistory, setGuessHistory, guessesRemaining, setGuessesRemaining, gameStatus, setGameStatus }) {
  const [userInput, setUserInput] = useState('');
  const [userScore, setUserScore] = useState(0);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const neoKey = 'TEMET NOSCE'; // process.env.REACT_APP_NEO_KEY;
  const dashKey = 'DASHBOARD';

  // Adjust dropdown position dynamically
  useEffect(() => {
    const adjustDropdownPosition = () => {
      if (inputRef.current) {
        const { top } = inputRef.current.getBoundingClientRect();
        if (dropdownRef.current) {
          dropdownRef.current.style.top = `${top + inputRef.current.offsetHeight}px`;
        }
      }
    };

    window.addEventListener('resize', adjustDropdownPosition);
    adjustDropdownPosition();

    return () => {
      window.removeEventListener('resize', adjustDropdownPosition);
    };
  }, []);

  // Handle Player Input

  const handleInputChange = (e) => {
    const input = e.target.value;
    setUserInput(input);
    if (input.length > 0) {
      setFilteredCountries(allCountries.filter(country => country.toLowerCase().includes(input.toLowerCase())));
    } else {
      setFilteredCountries([]);
    }
  };

  const handleSelection = (country) => {
    setUserInput(country);
    setFilteredCountries([]);
    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredCountries.length > 0) {
      handleSelection(filteredCountries[0]);
      e.preventDefault();
    }
  };
  
  const handleGuess = (e) => {
    e.preventDefault();
    if (userInput === neoKey) {
      console.log('Welcome to the Matrix');
      setGameStatus('neo00');
      return;
    }
    if (userInput === dashKey) {
      setGameStatus('dashboard');
      return;
    }
    if (!allCountries.includes(userInput.trim())) {
      alert('Please enter a valid country name from the dropdown.');
      return;
    }
    if (guesses[userInput.trim()]) {
      alert('You have already guessed this country!');
      return;
    }
    const isCorrect = gameData.countries.includes(userInput.trim());
    const updatedGuesses = { ...guesses, [userInput.trim()]: isCorrect };
    setGuesses(updatedGuesses);
    if (isCorrect) {
      setGuessHistory(prev => [...prev, '✈️']);
      setUserScore(prev => prev + 7);
    } else {
      setGuessHistory(prev => [...prev, '❌']);
      setGuessesRemaining(prev => prev - 1);
      setUserScore(prev => prev - 2)
    }
    setUserInput('');

    if (isCorrect && Object.values(updatedGuesses).filter(val => val).length === gameData.countries.length) {
      setGameStatus('won');
    } else if (!isCorrect && guessesRemaining <= 1) {
      setGameStatus('lost');
    }
  };

  // Share Button
  const handleShare = () => {
    const letter = gameData.letter + " "
    const score28 = "  " + (userScore >= 0 ? userScore : "0") + "/28";
    const score = letter + guessHistory.join('') + score28 + '\nhttps://landmarkle.com';
    navigator.clipboard.writeText(score).then(() => {
      alert('Score copied to clipboard!');
    }, () => {
      alert('Failed to copy score.');
    });
  };

  // Save Scores
  useEffect(() => {
    const saveGameResult = async () => {
      if (auth.currentUser) {
        setUserScore(userScore >= 0 ? userScore : 0);
        const gameResultData = {
          userId: auth.currentUser.uid,
          letter: gameData.letter,
          score: userScore,
          timestamp: new Date()
        };

        await addDoc(collection(firestore, "gameResults"), gameResultData);
        updateUserStats(auth.currentUser.uid, userScore);
      }
    };

    if (gameStatus === 'won' || gameStatus === 'lost') {
      saveGameResult();
    }
  }, [gameStatus, gameData, userScore]);  // Ensure all used state is listed in dependencies

   // Function to update user stats in Firestore
   const updateUserStats = async (userId, score) => {
    const userRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const gamesPlayed = userData.gamesPlayed + 1;
      const totalScore = userData.averageScore * (gamesPlayed - 1) + score;
      const averageScore = totalScore / gamesPlayed;

      await updateDoc(userRef, {
        gamesPlayed,
        averageScore
      });
    } else {
      // Initialize user data if it doesn't exist
      console.log("No such user! Initializing user data...");
      await setDoc(userRef, {
        gamesPlayed: 1,
        averageScore: score,
        fullName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL
      });
    }
  };

  return (
    <div className='container mb-40'>
        <h1 className='text-4xl font-bold text-gray-800 mb-5'>LANDMARKLE</h1>
        <div className="game-grid gap-0 sm:gap-2">
          {gameData && gameData.landmarks.map((landmark, index) => (
            <GameTile
              key={index}
              landmark={landmark}
              imageUrl={landmarkImages[index]}
              guessed={gameStatus !== 'playing' || !!guesses[gameData.countries[index]]}
              isCorrect={guesses[gameData.countries[index]]}
              country={gameData.countries[index]}
              hint={gameData.hints[index]}
              setGuessHistory={setGuessHistory}
              setUserScore={setUserScore}
            />
          ))}
        </div>
        <div className="score">
          {guessHistory.map((icon, index) => <span key={index}>{icon}</span>)}
          <span className='block-inline'>{userScore >= 0 ? userScore : "0" }/28</span>
        </div>
        {gameStatus !== 'playing' && (
          <div className='grid grid-cols-2 flex items-center gap-4'>
          <button 
            className='px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-300 mt-2'
            onClick={handleShare}>
              Share
          </button>
          <button 
            className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
            onClick={startGame}>
              Again
          </button>
        </div>
        )}
        <form onSubmit={handleGuess} className="mt-4 w-full flex flex-col items-center">
          {gameStatus === 'playing' && (
            <>
              <input
                ref={inputRef}
                type="text"
                value={userInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Start typing a country..."
                className="text-base w-full p-2 border-2 border-gray-300 rounded"
              />
              {filteredCountries.length > 0 && (
                <ul className="absolute bg-white border rounded sm:w-1/2 mt-12 z-10">
                  {filteredCountries.map((country, index) => (
                    <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => handleSelection(country)}>{country}</li>
                  ))}
                </ul>
              )}
              <button type="submit" className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2">Submit Guess</button>
            </>
          )}
        </form>
        {gameStatus !== 'playing' && <h2 className='text-2xl text-bold'>{gameStatus === 'won' ? 'You Win!' : 'Game Over!'}</h2>}
      </div>
  );
}

export default GamePlay;
