import { auth } from './firebase-config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const provider = new GoogleAuthProvider();

export const signIn = () => {
    signInWithPopup(auth, provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const token = result.credential.accessToken;
      console.log(token);
      // The signed-in user info.
      const user = result.user;
      console.log(user);
      // You could store the user info in the Firestore now.
    }).catch((error) => {
      console.log(error);
    });
  };