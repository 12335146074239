import axios from 'axios';

const API_KEYS = {
  openAI: process.env.REACT_APP_OPENAI_API_KEY,
  bing: process.env.REACT_APP_BING_SEARCH_API_KEY,
  google: process.env.REACT_APP_GOOGLE_API_KEY,
  googleCx: process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_ENGINE_ID,
  pexels: process.env.REACT_APP_PEXELS_API_KEY,
  pixabay: process.env.REACT_APP_PIXABAY_API_KEY,
  unsplash: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
  population: process.env.REACT_APP_POPULATION_API_KEY
};

// Function to fetch game data from OpenAI
export const fetchGameData = async (prompt) => {
  //console.log('Fetching Game Data Now with prompt:', prompt);
  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model: "gpt-4o",
      messages: [
        {role: "system", content: "You are integrated with an important piece of code, so ALWAYS answer in JSON format ONLY. Anything other than JSON format will break the system."},
        {role: "user", content: prompt}],
      max_tokens: 1000
    }, {
      headers: {
        'Authorization': `Bearer ${API_KEYS.openAI}`,
        'Content-Type': 'application/json'
      }
    });
    const dataString = response.data.choices[0].message.content.replace(/```json|```/g, '').trim();
    //console.log("Response from GPT-4: " + dataString);
    const parsedData = JSON.parse(dataString);
    return parsedData;
  } catch (error) {
    console.error('Error fetching game data:', error);
    return null;
  }
};

// Functions to sequentially fetch landmark images from multiple sources
const fetchFromBing = async (query) => {
  try {
    const response = await axios.get(`https://api.bing.microsoft.com/v7.0/images/search?q=${encodeURIComponent(query)}`, {
      headers: {'Ocp-Apim-Subscription-Key': API_KEYS.bing}
    });
    return response.data.value[0].thumbnailUrl;
  } catch (error) {
    console.error('Bing API failed:', error);
    throw error;
  }
};

const fetchFromGoogle = async (query) => {
  try {
    const response = await axios.get(`https://customsearch.googleapis.com/customsearch/v1`, {
      params: {
        q: query,
        cx: 'e3efb35a4d8b24f68',
        searchType: 'image',
        key: API_KEYS.google
      }
    });
    return response.data.items[0].link;
  } catch (error) {
    console.error('Google API failed:', error);
    throw error;
  }
};

const fetchFromPexels = async (query) => {
  try {
    const response = await axios.get(`https://api.pexels.com/v1/search`, {
      params: {
        query,
        per_page: 1
      },
      headers: {
        Authorization: `Bearer ${API_KEYS.pexels}`
      }
    });
    return response.data.photos[0].src.medium;
  } catch (error) {
    console.error('Pexels API failed:', error);
    throw error;
  }
};

const fetchFromPixabay = async (query) => {
  try {
    const response = await axios.get(`https://pixabay.com/api/`, {
      params: {
        q: query,
        key: API_KEYS.pixabay,
        image_type: 'photo',
        pretty: true
      }
    });
    return response.data.hits[0].previewURL;
  } catch (error) {
    console.error('Pixabay API failed:', error);
    throw error;
  }
};

const fetchFromUnsplash = async (query) => {
  try {
    const response = await axios.get(`https://api.unsplash.com/search/photos`, {
      params: {
        query,
        client_id: API_KEYS.unsplash
      }
    });
    return response.data.results[0].urls.small;
  } catch (error) {
    console.error('Unsplash API failed:', error);
    throw error;
  }
};

export const fetchLandmarkImage = async (landmark, country) => {
  const query=landmark + ", " + country;
  console.log('Come meet me at ' + query)
  try {
    return await fetchFromBing(query);
  } catch {
    try {
      return await fetchFromGoogle(query);
    } catch {
      try {
        return await fetchFromPexels(query);
      } catch {
        try {
          return await fetchFromPixabay(query);
        } catch {
          return await fetchFromUnsplash(query);
        }
      }
    }
  }
};

//Function to fetch world population
export const fetchPop = async () => {
  const options = {
    method: 'GET',
    url: 'https://get-population.p.rapidapi.com/population',
    headers: {
      'X-RapidAPI-Key': `${API_KEYS.openAI}`,
      'X-RapidAPI-Host': 'get-population.p.rapidapi.com'
    }
  };

  try {
    const response = await axios.request(options);
    console.log('THIS IS THE DATA: ' + response.data);
    return response.data.readable_format
  } catch (error) {
    console.error('THIS IS THE ERROR: ' + error);
    return '8 billion'
  }

}