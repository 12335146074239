import React from 'react';

function Wait03({ setGameStatus }) {
    return (
     <div>
        <h2 className='text-2xl mb-2'>Please, Play the Game</h2>
        
        <div className='text-justify sm:mx-2'>
          <p>Perhaps I have not been convincing enough, and if that's the case, it's not for lack of trying, it's for lack of knowledge. </p>
          <br/>
          <p>So I say to you, dear player: trust me, you'll want to play "Landmarkle".</p>
          <br/>
        </div>
        
        <p className='text-xl mb-2'>Play Now</p>
        <div className='grid grid-cols-2 flex items-center gap-4'>
          <button 
            className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('wait04')}>
              No
          </button>
          <button 
            className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('initial')}>
              Back
          </button>
        </div>
      </div>
    );
}

export default Wait03;