const initPrompt = `IMPORTANT: IN YOUR FINAL ANSWER TO ME, ONLY PRINT THE RESULTS OF STEP 5.

To enhance the generation of a random selection of countries and their iconic landmarks, execute the following steps in sequence.

Step 1: RANDOMLY select a letter from the English alphabet that is not F, H, J, O, Q, R, W, X, Y, or Z. Pick only from letters that are legal for this game. This selection must be TRULY random each time you execute this step. activeLetters = "ABCDEGIKLMNPSTUV"

Step 2: Using the letter selected in Step 1, identify four countries that start with this letter from the provided list below. Arrange these countries in alphabetical order and list them. For example, if the letter were M, you could pick Malta, Mexico, Morocco, and Mozambique. NEVER pick a country that's not on the list below. E.g., if the letter is T, NEVER pick Taiwan since Taiwan is not listed in the array below.

[
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas",
    "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
    "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei",
    "Bulgaria", "Burkina Faso", "Burundi", "Côte d'Ivoire", "Cabo Verde", "Cambodia",
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China",
    "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica", "Croatia",
    "Cuba", "Cyprus", "Czechia (Czech Republic)", "Democratic Republic of the Congo",
    "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini (fmr. Swaziland)",
    "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
    "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
    "Haiti", "Holy See", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
    "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
    "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar (formerly Burma)",
    "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger",
    "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan",
    "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru",
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda",
    "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
    "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan",
    "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga",
    "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda",
    "Ukraine", "United Arab Emirates", "United Kingdom", "United States of America",
    "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia",
    "Zimbabwe"
  ]

Step 3: For each of the four countries listed from Step 2, identify one of the top three most iconic and well-known landmarks within that country. The landmarks should be universally recognized. The names of the landmarks could start with any letter.

Step 4: For each landmark, provide a one-sentence hint that will help the player guess which country the landmark is in, but do not make the answer obvious. Assume the player is an average American. Examples: If the landmark is "Grand Place" and the country is "Belgium", the hint might be "The country is famous for its chocolate, beer, and waffles.". If the landmark is "Royal Palaces of Abomey" and the country is "Benin", the hint might be "It is known for its significant role in the history of the African slave trade."

Step 5: Provide the results from Steps 1, 2, 3, and 4 in a structured JSON format.

IMPORTANT: IN YOUR FINAL ANSWER TO ME, ONLY PRINT THE RESULTS OF STEP 5. USE THE FOLLOWING FORMAT:

{
“letter”:”X”,
“countries”:[“country1”, “country2”, “country3”, “country4”],
“landmarks”:[“landmark1”, “landmark2”, “landmark3”, “landmark4”],
“hints”:[“hint1”, “hint2”, “hint3”, “hint4”]
}

`

export default initPrompt;