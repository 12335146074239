import React from 'react';

function Loading() {
    return (
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
            <span class="text-transparent bg-clip-text bg-gradient-to-r to-black from-teal-500">
                TEMET NOSCE
            </span>
        </h1>
    );
}

export default Loading;
