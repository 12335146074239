import React, { useState, useEffect } from 'react';
import { firestore } from './firebase-config';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

function Dashboard() {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const scoresRef = collection(firestore, "users");
      const q = query(scoresRef, orderBy("averageScore", "desc"), limit(10));
      const querySnapshot = await getDocs(q);
      const leaderboardData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      console.log(leaderboardData); // Ensure data is correctly fetched and logged
      setLeaderboard(leaderboardData);
    };

    fetchLeaderboard();
  }, []);

  return (
    <div className='container mx-auto mt-10'>
      <h1 className='text-3xl font-bold text-center mb-4'>Who's Learning?</h1>
      <div className='max-w-2xl mx-auto'>
        <table className='table-auto w-full'>
          <thead>
            <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
              <th className='py-3 px-6 text-left'>Name</th>
              <th className='py-3 px-6 text-left'>Score</th>
              <th className='py-3 px-6 text-center'>Games</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 text-sm font-light'>
            {leaderboard.map(user => (
              <tr className='border-b border-gray-200 hover:bg-gray-100' key={user.id}>
                <td className='py-3 px-6 text-left whitespace-nowrap'>
                  <div className='flex items-center'>
                    <div className='mr-2'>
                      <img className='w-6 h-6 rounded-full' src={user.photoURL} alt="avatar"/>
                    </div>
                    <span>{user.fullName}</span>
                  </div>
                </td>
                <td className='py-3 px-6 text-left'>
                  <span>{user.averageScore.toFixed(0)}</span>
                </td>
                <td className='py-3 px-6 text-center'>
                  <span>{user.gamesPlayed}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
