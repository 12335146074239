import React from 'react';

function Wait05({ setGameStatus }) {
    return (
        <div>
          <h2 className='text-2xl mb-2'>Then you are going to like this!</h2>
          <div className='text-justify sm:mx-2'>
            <p>"Landmarkle", when played right (and you are playing it right), is a unique opportunity for humans to get a feel for how manchine learning emerges from repetitive, feedback-looped attempts to get a high score.</p>
            <br/>
            <p>It's possible you're reading this right now but you've never even played a single round of "Landmarkle". Maybe you're already great at it! Have you ever gotten a perfect 28/28? How often do you score a 20 or above?</p>
            <br/>
          </div>
          
          <p className='text-xl mb-2'>
            Your neural network and ours are not so different after all!
          </p>
          
          <div className='grid grid-cols-2 flex items-center gap-4'>
            <button 
              className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
              disabled
              onClick={() => setGameStatus('initial')}>
                Back
            </button>
            <button 
              className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('wait06')}>
                More
            </button>
          </div>
        </div>
    );
}

export default Wait05;