import React from 'react';

function Wait00({ setGameStatus }) {
    return (
      <div>
        <h2 className='text-2xl mb-2'>What is Artificial Intelligence?</h2>
        <div className='text-justify sm:mx-2'>
          <p><b>Artificial Intelligence</b> is designed to mimic human intelligence, capable of performing tasks that typically require a "real" noggin', such as thinking of random countries, finding pictures of famous landmarks online, and coding little online games.</p>
          <br/>
          <p>A <b>Large Language Model</b>, like GPT-4, is a specific type of AI that specializes in understanding and generating human-like <span className='drop-shadow'>text</span>, trained on vast amounts of written material.</p>
          <br/>
          <p>Crazy how a machine can eat everything ever spelled on the Internet and magically know how to make a fun app like Landmarkle!</p>
          <br/>
        </div>
        <p className='text-xl mb-2'>Enjoy your "game"!</p>
        <div className='grid grid-cols-2 flex items-center gap-4'>
          <button 
            className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('initial')}>
              Back
          </button>
          <button 
            className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('wait01')}>
              "Game"??
          </button>
        </div>
      </div>
    );
}

export default Wait00;
