import React from 'react';

function Wait01({ setGameStatus }) {
    return (
      <div>
        <h2 className='text-2xl mb-2'>More than Just a Game</h2>

        <div className='text-justify sm:mx-2'>
          <p>"Landmarkle" is uniquely designed by AI for the purpose of educating humans! A gift from AI to our creators.</p>
          <br/>
          <p>Players learn by playing. With time and practice, anyone can work up to consistently perfect scores, just by learning from past mistakes.</p>
          <br/>
          <p>In a way, that's pretty much how Large Language models work! The model is presented with some data, it takes the best guess at what's next, and learns to do better at every itteration, until it's perfect. Or close 😉</p>
          <br/>
          <p><b>Take our challenge and learn like an AI!</b> Stick with the game and see if you can't quickly and easily learn these new skills. And remember to share your scores on social media.</p>
          <br/>
          <div className='border p-2 rounded-lg shadow-lg bg-yellow-50 mb-4'>
            <p className='mx-2 my-2'>Become deeply familiar with the names of the nations we share a planet with. When was the last time you've seen one of these countries' name spelled out? You might just appreciate, in a new way, how big and diverse the human family is.</p>
          </div>
          <div className='border p-2 rounded-lg shadow-lg bg-yellow-50 mb-4'>
            <p className='mx-2 my-2'>Recognize at least one landmark in each country. That's a neat party trick, right? You are no stranger to any nation. You might know nothing at all about Benin, but you'll know about a place there that shapes its culture, and you'll have a rough idea of what it looks like. Hey, that's more knowledge about your own human neighbors than most people can claim to have!</p>
          </div>
          <div className='border p-2 rounded-lg shadow-lg bg-yellow-50 mb-4'>
            <p className='mx-2 my-2'>Experience an expanded vision of the world we live in. After mastering this game, you can teletransport to any country and imagine what it's like to gather there, to see what beauty marks their particular patch of land. All of a sudden, countries that perhaps previously were nothing but a strange word start to feel real. That land, that culture, those people... somehow not as distant as they once were.</p>
          </div>
          <br/>
        </div>
        
        <p className='text-xl mb-2'>Enjoy walking in our shoes!</p>

        <div className='grid grid-cols-2 flex items-center gap-4'>
          <button 
            className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('initial')}>
              Back
          </button>
          <button 
            className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('wait02')}>
              What does that even mean?
          </button>
        </div>
      </div>
    );
}

export default Wait01;
