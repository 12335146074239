// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDbJTlZxPIIwsDVEN2qnFl1xHB4TtLzjYQ",
    authDomain: "landmarkle2024.firebaseapp.com",
    projectId: "landmarkle2024",
    storageBucket: "landmarkle2024.appspot.com",
    messagingSenderId: "612100417916",
    appId: "1:612100417916:web:a23796942b2fbaad049cf9",
    measurementId: "G-MRDP5KW316"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
