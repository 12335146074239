import React, { useEffect, useState } from 'react';
import { signIn } from './SignIn';
import { auth, firestore } from './firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';

function Initial({ setGameStatus, startGame }) {
    const [user, setUser] = useState(null);
    const [averageScore, setAverageScore] = useState(null);
    const [detailedScores, setDetailedScores] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const activeLetters = "ABCDEGIKLMNPSTUV"; // Letters used in the game

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                loadUserScores(currentUser.uid);
            }
        });
        return unsubscribe;
    }, []);

    const loadUserScores = async (userId) => {
        // Individual Letter Scores (drill down to game results)
        const scoresRef = collection(firestore, "gameResults");
        const q = query(scoresRef, where("userId", "==", userId), orderBy("timestamp", "desc"));
        const querySnapshot = await getDocs(q);
        let scoresByLetter = {};
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const { letter, score } = data;
            if (!scoresByLetter[letter]) scoresByLetter[letter] = [];
            scoresByLetter[letter].push(score);
        });

        calculateScores(scoresByLetter);
    };
    

    const calculateScores = (scoresByLetter) => {
        let totalScore = 0;
        let gamesCount = 0;
        let averages = {};
        Object.keys(scoresByLetter).forEach(letter => {
            const scores = scoresByLetter[letter].slice(0, 10); // Take only the last 10 scores
            const avg = scores.reduce((acc, s) => acc + s, 0) / scores.length;
            averages[letter] = avg.toFixed(1);
            totalScore += avg * scores.length;
            gamesCount += scores.length;
        });

        setAverageScore(totalScore / gamesCount);
        setDetailedScores(averages);
    };

    return (
        <div className='gap-4'>
            <h1 className='text-4xl font-bold text-gray-800 mb-5'>Welcome to Landmarkle!</h1>
            <div className='grid grid-cols-2 flex items-center gap-4 my-2'>
                <button className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2' 
                onClick={() => setGameStatus('howToPlay')}>
                    How to Play
                </button>
                <button className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2' 
                onClick={startGame}>
                    Play
                </button>
            </div>
            {user && (
                <div className='mt-2'>
                    <p className='text-xl text-yellow-600'>
                        Welcome home, {user.displayName.split(" ")[0]}!
                    </p>
                    {averageScore && (
                    <p className='text-md font-semibold text-yellow-600' onClick={() => setShowDetails(!showDetails)}>
                        Your average score is {averageScore.toFixed(0)} out of 28.
                    </p>
                    )}
                    {showDetails && (
                        <div className='text-sm'>
                            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2'>
                                {Object.entries(detailedScores).map(([letter, avgScore]) => (
                                    <p key={letter}>{letter}: {avgScore}</p>
                                ))}
                            </div>
                            <p className='mt-4'>Keep playing to score for: {activeLetters.split('').filter(l => !detailedScores[l]).join(', ')}</p>
                        </div>
                    )}
                </div>
            )}
            {!user && (
                <button className='px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-300 mt-2' onClick={signIn}>
                    Track Scores
                </button>
            )}
            <p className='text-xs mt-8'>A game by <a href='http://tomrainscodes.com' target="_blank" rel="noreferrer" className='text-teal-500'>Tom Rains</a> and <a href='http://hiredalmo.com' target="_blank" rel="noreferrer" className='text-teal-500'>Dalmo Mendonça</a></p>
        </div>
    );
}

export default Initial;
