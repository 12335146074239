import React, { useEffect, useState } from 'react';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

function Wait99({ setGameStatus, startGame }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);
  
  return (
    <div>
      <h2 className='text-2xl mb-2'>It's Time</h2>
      <p>
        Go play "Landmarkle" now
        {user && (
          <span>, {user.displayName.split(" ")[0]}</span>
        )}    
      . Get good at it. Then come back to me.</p>
      <br/>
      <p>Whenever you're ready...</p>
      <br/>
      <p className='text-xl mb-2'>Enter Password to Proceed</p>
      <div className='grid grid-cols-2 flex items-center gap-4'>
        <button 
          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
          onClick={() => setGameStatus('initial')}>
            Back
        </button>
        <button 
          className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
          onClick={startGame}>
            Play
        </button>
      </div>
    </div>
  );
}

export default Wait99;