import React, { useState } from 'react';

function GameTile({ landmark, imageUrl, guessed, isCorrect, country, hint, setGuessHistory, setUserScore }) {
    const [showHint, setShowHint] = useState(false);

    const tileClass = isCorrect ? 'bg-green-500' : 'bg-red-500';
    const textStyle = guessed ? `text-center text-white font-bold ${tileClass}` : 'text-center';

    function countHint() {
        if (showHint === false) {
            setShowHint(true);
            setGuessHistory(prev => [...prev, '🔎']);
            setUserScore(prev => prev - 1);
        }
    };

    return (
        <div className={`tile border p-2 rounded-lg shadow-lg cursor-pointer ${showHint ? 'shadow-yellow-500' : ''} hover:shadow-yellow-500 focus:shadow-yellow-500 active:shadow-yellow-500`} style={{ background: guessed ? (isCorrect ? '#4CAF50' : '#F44336') : '#fff' }}
            onClick={() => countHint()}>
                <div style={{
                    width: '100%',   // Takes the full width of the container
                    height: 0,
                    paddingBottom: '100%', // Maintains aspect ratio 1:1
                    position: 'relative'
                }}>
                    <img src={imageUrl} alt={landmark} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px' // Optional: Matches your rounded-lg style
                    }} />
                </div>

                <p className={textStyle}>{landmark}</p>

                {showHint && (
                    <div className="text-center mt-2">
                        <svg className="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                        <p>
                            {hint}
                        </p>
                    </div>
                )}

                {guessed && (
                    <p className="text-center text-white font-bold mt-2">
                        {country.toUpperCase()}
                    </p>
                )}
        </div>
    );
}

export default GameTile;
