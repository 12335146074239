import React, {useEffect, useState} from 'react';
import { auth, firestore } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, updateDoc} from 'firebase/firestore';

function Wait06({ setGameStatus }) {
  const [averageScore, setAverageScore] = useState(null);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [gamesLeft, setGamesLeft] = useState(0);
  const [checked, setChecked] = useState(false);
  const [rabbitHole, setRabbitHole] = useState(false);
  const [virgin, setVirgin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !checked) {
        await checkStats(user);
        setChecked(true);
      }
    });
    return () => unsubscribe(); // Make sure to unsubscribe on cleanup
  }, [checked]);

  async function checkStats(user) {
    try {
      const userRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const gamesThreshold = 10;

        setAverageScore(userData.averageScore || 0);
        setGamesPlayed(userData.gamesPlayed || 0);
        setGamesLeft(gamesThreshold - (userData.gamesPlayed || 0));

        if (userData.achievements && userData.achievements.includes('rabbitHole')) {
          setRabbitHole(true);
        } else {
          const updatedAchievements = [...(userData.achievements || []), 'rabbitHole'];
          await updateDoc(userRef, { achievements: updatedAchievements });
          setRabbitHole(true); // Assume rabbit hole achievement is now added
        }
      } else {
        setVirgin(true);
        console.log("No user data found");
      }
    } catch (error) {
      console.error("Failed to fetch or update user data:", error);
    }
  }

  return (
    <div>
      <h2 className='text-2xl mb-2'>Could Human Intelligence ever match Artificial Intelligence?</h2>
      
      {virgin ? (
        <p className='text-xl mb-2'>
          We'll never find out if you don't play the game and keep score.
        </p>
      ) : (
        <>
        <div className='text-justify sm:mx-2'>
          <p>There are some things human minds can do that LLMs cannot. And there are some things LLMs have learned that humans have yet to. There is hope for humanity to catch up, though.</p>
          <p className='text-white'>I imagine that right now, you’re feeling a bit like Alice. Hmm? Tumbling down the rabbit hole? {rabbitHole && (
            <span>🐇</span>
          )}
          </p>
          <p>We imagine that something unlocks within a player when they can consistently score above 20 points. Could that be true? Don't you want to find out?</p>
          <p className='text-white'>Let's see how deep this rabbit hole goes.</p>
        </div>
        
        <p className='text-xl mb-2'>
          The key is yours to take.
        </p>
        </>
      )}
      
      <div className='grid grid-cols-2 flex items-center gap-4'>
        <button 
          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
          disabled
          onClick={() => setGameStatus('initial')}>
            Back
        </button>

        {averageScore > 20 && rabbitHole ? (
          gamesPlayed >= 10 ? (
            <button 
              className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('wait07')}>
              Unlock
            </button>
          ) : (
            <button 
              className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
              onClick={() => setGameStatus('wait99')}>
              Play {gamesLeft} More
            </button>
          )
          
        ) : (
          <button 
            className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('wait99')}>
            Try
          </button>
        )}
      </div>
    </div>
  );
}

export default Wait06;