import React from 'react';

function Neo00({ setGameStatus }) {
    return (
        <div>
            <h2 className='text-2xl mb-2'>Welcome Back</h2>
            <p>"Know Yourself." Got it, Neo? </p>
            <br/>
            <p>Whenever you're ready...</p>
            <br/>
            <p className='text-xl mb-2'>Enter New Password to Proceed</p>
            <div className='grid grid-cols-2 flex items-center gap-4'>
              <button 
                className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
                onClick={() => setGameStatus('initial')}>
                  Back
              </button>
              <button 
                className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
                onClick={() => setGameStatus('initial')}>
                  Home
              </button>
            </div>
        </div>
    );
}

export default Neo00;