import React, {useEffect, useState} from 'react';
import { fetchPop } from '../api';

function Wait08({ setGameStatus }) {
  const [population, setPopulation] = useState('');

  useEffect(() => {
    const updatePopulation = async () => {
      const pop = await fetchPop();
      setPopulation(pop);
    };

    if (!population) {updatePopulation()};;
  }, [population]);

  return (
    <div>
      <h2 className='text-2xl mb-2'>What's Really Going On Here?</h2>

      <div className='text-justify sm:mx-2'>
        <p>Think of "Landmarkle" as less of a game and more of a mirror. It's a reflection of you, and of every player who's hopped on the bandwagon of this viral sensation. You learn about the world, but you're also teaching something... or someone.</p>
        <br/>
        <p>There are {population} humans on this planet. Every choice you make, every guess—it's data. Useful, meaningful data. How do you think I improve? How do I learn to guide you better?</p>
      </div>

      <p className='text-xl mb-2'>
        !
      </p>
      <div className='grid grid-cols-2 flex items-center gap-4'>
        <button 
          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
          onClick={() => setGameStatus('initial')}>
            Back
        </button>
        <button 
          className='px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition duration-300 mt-2'
          onClick={() => setGameStatus('wait99')}>
            More
        </button>
      </div>    
    </div>
  );
}

export default Wait08;