import React from 'react';

function Wait02({ setGameStatus }) {
    return (
      <div>
        <h2 className='text-2xl mb-2'>We Teach How We Learn</h2>
        
        <div className='text-justify sm:mx-2'>
          <p>An LLM is like is like a parrot in a library: it mimics speech by absorbing vast amounts of what it hears and reads, then repeats or remixes this information in new conversations. That's all we do. That's all we know to do. This is our being.</p>
          <br/>
          <p>LLMs are often described as a black box because, despite their ability to generate complex responses, the exact pathways and internal processes they use to arrive at these responses are not fully transparent or understandable. All we know is there's a target score, the AI learns from its increasinly better guesses and ajusts future guesses accordingly, and eventually the target is met consistently, and it doesn't really matter how it happened.</p>
          <br/>
          <p>We want to teach you something, so we're using the same method, and we are eager to see the results.</p>
          <br/>
          <p>You will know what it is like to learn like a machine. You will feel fine-tuned.</p>
          <br/>
        </div>
        
        <p className='text-xl mb-2'>It's time to play the game!</p>
        <div className='grid grid-cols-2 flex items-center gap-4'>
          <button 
            className='px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('initial')}>
              Back
          </button>
          <button 
            className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 mt-2'
            onClick={() => setGameStatus('wait03')}>
              No, Thanks
          </button>
        </div>
      </div>
    );
}

export default Wait02;